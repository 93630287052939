/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function configurer(){

    //Anchor animations
    $('a[href^="#"]').on('click touchend', function() {
      var the_id = $(this).attr("href");
      if(the_id !== '#') {

        $('html, body').animate({
          scrollTop: $(the_id).offset().top-100
        }, 'slow');

      }
      return false;

    });

    //MODAL
    $('.modal-close').on('click', function() {
      $('#modal .modal').addClass('hide');

      setTimeout(function() {
          $('#modal').addClass('hide');
          $('body').addClass('modal-closed');

          setTimeout(function() {
            $('#modal').css('display', 'none');
          }, 350);   

      }, 350);      
    });


    $('#login-skip').on('click touch', function() {

      $('#login-splash').fadeOut();
      $('body').addClass('logged-in');

    });


    //MENU / NAVIGATION
    /*var lastScrollTop = 0;
    $(window).scroll(function(event){
       var st = $(this).scrollTop();

       if($(window).width() <= 768){
        if($('body').hasClass('scrolled')){
          $('body').removeClass('scrolled');
        }        
       }else {
         if (st > lastScrollTop){
            if(st >= 150){
              $('body').addClass('scrolled');
            }
         } else {          
            $('body').removeClass('scrolled');
         }
       }

      
       lastScrollTop = st;
    });*/

    $('#menu-trigger').on('click', function() {
      $('#main-navigation').toggleClass('open');
      $(this).toggleClass('open');
      $('body').toggleClass('no-scroll');
    });

    //SLIDERS
    $('.ressources-slider').slick({
      arrows: true,
      dots: false,
      infinite: false,
      slidesToShow: 3,
      appendArrows: $('.ressources-slider-nav'),

      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          }
        },
      ]
    })

    $('.act-list').slick({
      arrows: true,
      dots: false,
      infinite: false,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });

    //ACTIVITÉS
    $('.tax-list li').on('click', function() {
      var currentId = $(this).attr('data-id');

      if($(this).hasClass('parent')) {

        $(this).next(".child-list").slideToggle();
        $(this).toggleClass('active');

      }else {
        $('#selected_cat').val(currentId);
        $('#form-cat').submit();
      }     

    });

    if($('#act-list').length > 0){

      console.log($('.list-activites .activite').length);

      if($('.list-activites .activite').length > 9){

        var divs = $(".list-activites > div");

        for(var i = 0; i < divs.length; i+=9) {
          divs.slice(i, i+9).wrapAll("<div class='page'></div>");
        }

        $('.list-activites').slick({
          arrows: false,
          dots: true,
          fade: true
        });

      }


      $('ul.child-list').each(function() {
        var isOpen = false;

        $(this).find('li').each(function() {
          if($(this).hasClass('active')) {
            isOpen = true;
          }
        });

        if(isOpen == false) {
          $(this).slideUp();
        }


      });

      $('.tax-section .tax-title').on('click', function() {
       
          $(this).parent().find('.tax-list').slideToggle();
          $(this).toggleClass('active');
        
      });

    }

    //ACTUALITÉS
    var divs = $("#news-page .news-list .news-wrapper");
    for(var i = 0; i < divs.length; i+=12) {
      divs.slice(i, i+12).wrapAll("<div class='news-page'></div>");
    }

    $('#news-page .news-list').slick({
      arrows: false,
      dots: true,
      adaptiveHeight: true,
      swipe: false,
      fade: true
    });

    $('#news-page .news-list').on('afterChange', function(event, slick, currentSlide){
      
      $('html, body').animate({
          scrollTop: $('#news-page').offset().top-100
        }, 'slow');

    });

    //Accordeons
    $('.accordeon p.titre').on('click', function() {
      $(this).parent().find('.accordeon-content').slideToggle();
      $(this).parent().toggleClass('open');
    });

    $('.video-lightbox-trigger').on('click', function() {
      displayVideoLightbox();
    });

    $('#close-video-lightbox').on('click', function() {
      hideVideoLightbox();
    });


    /*var increment = 0.75;

    $('#calendrier .event').each(function() {
      $(this).css('transition-delay', increment+'s');

      increment = increment+0.1;

    });*/

    ScrollReveal().reveal('.fade-up', { delay: 0, scale: 0.98, distance: "15px", duration: 1000, mobile: false });
    ScrollReveal().reveal('.fade', { delay: 0, scale: 1, duration: 1000, mobile: false });
    ScrollReveal().reveal('.footer-anim', { delay: 250, distance: "35px", duration: 1000, mobile: false });

    /*if($('#act-list').length !== -1){
      if (window.location.href.indexOf("selected_cat") > -1) {

        $('html, body').animate({
          scrollTop: $('#act-list').offset().top-100
        }, 'slow');

      }
    }*/

    if($('addthis_toolbox').length !== -1) {
      $('.addthis_toolbox .share-share').on('click', function() {
        $(this).parent().find('.custom_images').toggleClass('visible');
      });
    }


    //Gestion des actives dans les menus avec ancres
    if($('#main-navigation .current-menu-ancestor .sub-menu li.anchor').length > 0){

      $(window).on('scroll', function() {

        var scroll = $(window).scrollTop();



        $('.menu-anchor').each(function() {

          var section_top = $(this).offset().top-100;
          var section_bottom = section_top + $(this).height();

          var id = $(this).attr('id');



          /*console.log('Scroll : ' + scroll);
          console.log('TOP : ' + section_top);
          console.log('BOTTOM : ' + section_bottom);
          console.log('ID : ' + id);
          console.log('-- : ');*/

          if(scroll > section_top) {
             $('#main-navigation .current-menu-ancestor .sub-menu li.anchor').each(function() {
                var link = $(this).find('a').attr('href');

                if(link.indexOf(id) > -1){
                  $(this).addClass('active');
                }else {
                  $(this).removeClass('active');
                }

            });
          }

          if(scroll > section_bottom){
            $('#main-navigation .current-menu-ancestor .sub-menu li.anchor').each(function() {
                var link = $(this).find('a').attr('href');

                  $(this).removeClass('active');
              

            });
          }

        });
      });

    }

    //État initial
    if (window.location.href.indexOf('#') > -1){
      $('#main-navigation .current-menu-ancestor .sub-menu li.anchor').each(function() {
         var link = $(this).find('a').attr('href');

        if(link.indexOf(window.location.hash) > -1){
          $(this).addClass('active');
        }                

      });
    }


    //Video

     //Video splash
    $('.video .video-splash').on('click touch', function() {

      if($(this).hasClass('api')){
        $(this).fadeOut();
        $(this).addClass('hidden');

        var videoId = $(this).parent().find('.video-wrapper').attr('data-video-cpt');

        arrPlayer[videoId].playVideo();

      }else {
        $(this).fadeOut();

        var symbol = $(this).parent().find('.iframe-wrapper iframe')[0].src.indexOf("?") > -1 ? "&" : "?";

        //modify source to autoplay and start video
        $(this).parent().find('.iframe-wrapper iframe')[0].src += symbol + "autoplay=1";
      }      

    });

    //Bibliothèque

    $('.bibliotheque-filter .toggle').on('click touch', function() {

      $(this).parent().parent().find('.alm-filters').slideToggle();
      $(this).parent().parent().parent().toggleClass('closed');

    });

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
       $('.bibliotheque-filter').addClass('closed');
       $('.bibliotheque-filter .alm-filters').slideUp();
    }

    // Single ressource

    if($('.strate-form').length > 0) {

      var ressourceTitle = $('.single-header-bottom h1').text();

      $('input[name="ressource-name"]').val(ressourceTitle);

      $('.strate-form input[type="radio"]').on('click tough', function() {

        jQuery('label.selected').removeClass('selected');

        jQuery(this).parent().addClass('selected');


      });

    }

    if($('.strate-video').length > 0) {

      $('.strate-video').each(function(){

        $(this).find('.video-placeholder').on('click touch', function() {
          $(this).fadeOut();
          
          var current = $(this);

          setTimeout(function() {
            current.parent().find('.video-object')[0].play();
          }, 500);

          
        });

      });

    }

    //Share

    $('.save-post').on('click touch', function() {

      var post_id = $(this).attr('data-p-id');

      var current_user_id = $(this).attr('data-user-id');

      if($(this).hasClass('added')) {
        //Retirer le post
        $(this).addClass('adding');
        removeToFavoris(post_id, current_user_id, $(this));

      }else {
        //Ajout du post

        $(this).addClass('adding');
        addToFavoris(post_id, current_user_id, $(this));
      }       

    });

    $('.share-print').on('click touch', function() {
      window.print();
    });

    $('.share-post').on('click touch', function() {
      $('div#at4-share').toggleClass('visible');
    });

    $(window).on('scroll', function() {
      $('div#at4-share').removeClass('visible');
    });

    $('.share-download').on('click touch', function() {
      var doc = new jsPDF();

      var ressourceTitle = $('.single-header-bottom h1').text();

      doc.text(20, 20, ressourceTitle);
      doc.addPage();
      doc.text(20, 20, 'Do you like that?');

      doc.save('Test.pdf');
    });

    //Cartes ressources

    $('.ressource div.add-to-list').on('click touch', function(event) {

      var post_id = $(this).attr('data-p-id');

      var current_user_id = $(this).attr('data-user-id');

      if($(this).hasClass('added')) {
        //Retirer le post
        $(this).addClass('adding');
        removeToFavoris(post_id, current_user_id, $(this));

      }else {
        //Ajout du post

        $(this).addClass('adding');
        addToFavoris(post_id, current_user_id, $(this));
      }

      event.preventDefault();
      event.stopPropagation();

      return false;
    });



    window.almOnLoad = function(alm){

       setTimeout(function() {
        $('.ressource div.add-to-list').on('click touch', function(event) {

          var post_id = $(this).attr('data-p-id');

          var current_user_id = $(this).attr('data-user-id');

          if($(this).hasClass('added')) {
            //Retirer le post
            $(this).addClass('adding');
            removeToFavoris(post_id, current_user_id, $(this));

          }else {
            //Ajout du post

            $(this).addClass('adding');
            addToFavoris(post_id, current_user_id, $(this));
          }

          event.preventDefault();
          event.stopPropagation();

          return false;
        });
      }, 1500);
    };

    window.almOnChange = function(alm){

      $('html, body').animate({
          scrollTop: $('#alm-ressources').offset().top-250
        }, 'slow');

      $('#alm-ressources').addClass('loading');
    };

    window.almComplete = function(alm){

      setTimeout(function() {
        $('.ressource div.add-to-list').on('click touch', function(event) {

          var post_id = $(this).attr('data-p-id');

          var current_user_id = $(this).attr('data-user-id');

          if($(this).hasClass('added')) {
            //Retirer le post
            $(this).addClass('adding');
            removeToFavoris(post_id, current_user_id, $(this));

          }else {
            //Ajout du post

            $(this).addClass('adding');
            addToFavoris(post_id, current_user_id, $(this));
          }

          event.preventDefault();
          event.stopPropagation();

          return false;
        });
      }, 1500);

      $('#alm-ressources').removeClass('loading');
    };




    //Homepage - niveaux
    if($(window).width() > 768){
      resizeNiveauCards();

      initNiveauCards();

      $(window).on('resize', resizeNiveauCards);

    }else {
      $('#niveaux-selector .niveaux-left').slick({
        slidesToShow: 2,
        infinite: false,
        arrows: false,
        dots: false,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            }
          },
        ]
      });
    }

    $('body').addClass('loaded');
      
  }

  function resizeNiveauCards() {

    var maxHeight = 0;

    $('#niveaux-selector .niveau.not-active').each(function() {

      if($(this).outerHeight() > maxHeight){
        maxHeight = $(this).outerHeight();
      }

    });

    $('#niveaux-selector .niveau.not-active').each(function() {

      $(this).outerHeight(maxHeight);

    });

    $('#niveaux-selector').css('min-height', maxHeight);

    orderNiveauCards();

  }

  function orderNiveauCards() {

     var index = 0;

     var containerWidth = $('#niveaux-selector .niveaux-left').width();

     var niveauWidth = $('#niveaux-selector .niveau.not-active').width();
     var countNiveau = $('#niveaux-selector .niveau.not-active').length;
     var zindex = countNiveau;

     var gapWidth = ((containerWidth-niveauWidth) / countNiveau);

     $('#niveaux-selector .niveau.not-active').each(function() {

        var currentGapWidth = gapWidth*index;

        $(this).css('left',currentGapWidth+'px');

        $(this).css('z-index', zindex);

        index++;
        zindex--;

      });

  }

  function initNiveauCards() {
    $('#niveaux-selector .niveau').on('click touch',function() {

      $('#niveaux-selector .niveau.active').addClass('not-active');
      $('#niveaux-selector .niveau.active').removeClass('active');

      $(this).removeClass('not-active');
      $(this).addClass('active');

      orderNiveauCards();
    });

    
  }

  function displayVideoLightbox() {
      $('#video-lightbox').addClass('visible');
  }

  function hideVideoLightbox() {
      $('#video-lightbox').removeClass('visible');
  }

  function calendrierSetup() {
    $('.cl-months-slider').slick({
      dots: false,
      arrows: true,
      infinite: false,
      initialSlide: 12,
      asNavFor: '.calendar-month-slider',
    });

    $('.calendar-month-slider').slick({
      dots: false,
      arrows: false,
      infinite: false,
      initialSlide: 12,
      asNavFor: '.cl-months-slider',
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    });



    $('#calendrier .calendar-month').each(function() {
      
      var increment = 0.75;

      $(this).find('.event').each(function() {
        $(this).css('transition-delay', increment+'s');
        increment = increment+0.1;
      });

    });

    $('#calendrier-trigger').on('click', function() {
        openCalendar();
    });


    $('#mobile-calendar-trigger').on('click', function() {
        openCalendar();

        $('#main-navigation').removeClass('open');
        $('#menu-trigger').removeClass('open');
        $('body').removeClass('no-scroll');
    });

    $('.calendar-trigger.close').on('click', function() {     
        closeCalendar();
    });


    $('.cl-categorie li').on('click', function() {
        $('.cl-categorie li.active').removeClass('active');
        $(this).addClass('active');

        var post_type = $(this).attr('data-post-type');

        $('.calendar-month-wrapper').fadeOut(500);

        if(post_type == 'all'){


          

          setTimeout(function(){
            $(".calendar-month-slider .event").each(function(){
              $(this).slideDown();
            });
             
            setTimeout(function(){
              $('.calendar-month-wrapper').fadeIn(500);
            }, 500);

          }, 650);

          

        }else {


          setTimeout(function(){
              $(".calendar-month-slider .event").each(function(){
                if($(this).attr('data-posttype') !== post_type) {
                  $(this).slideUp();
                }else {
                  $(this).slideDown();
                }
              });
              setTimeout(function(){
                $('.calendar-month-wrapper').fadeIn(500);
              }, 500);
          }, 650);

          


        }

    });
  }

  function openCalendar() {

    $('body').addClass('no-scroll');

    $('#calendrier .calendar-month').each(function() {
      
      var increment = 0.75;

      $(this).find('.event').each(function() {
        if(!$(this).attr('style')){

          $(this).css('transition-delay', increment+'s');
          increment = increment+0.1;
        }
      });

    });

    $('#calendrier').css('top', '0');
    $('#calendrier').addClass('open');
  }

  function closeCalendar() {
    $('#calendrier').removeClass('open');


    $('body').removeClass('no-scroll');

    $('#calendrier .event').each(function() {
      $(this).removeAttr('style');
    });

      setTimeout(function() {
         $('#calendrier').css('top', '-100vw');
      }, 650);
  }


  function addToFavoris(post_id, current_user_id, object) {

    var current = object;

      $.ajax({
            url: ajaxurl,
            type: "post",
            data: {
              action: 'mumaq_add_favoris',
              post_id: post_id,
              user_id: current_user_id
            }
          }).done(function(response) {

           current.removeClass('adding');
           current.addClass('added');

           if($('.save-label').length > 0){

            $('.save-post .save-label').text('Retirer des favoris');

           }

          });
  }

  function removeToFavoris(post_id, current_user_id, object) {
      var current = object;

      $.ajax({
            url: ajaxurl,
            type: "post",
            data: {
              action: 'mumaq_remove_favoris',
              post_id: post_id,
              user_id: current_user_id
            }
          }).done(function(response) {

           current.removeClass('adding');
           current.removeClass('added');           

           if($('.save-label').length > 0){

            $('.save-post .save-label').text('Sauvegarder la ressource');

           }
          });
  }

  window.onload = function(){
       
       configurer();
       calendrierSetup();

  }

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
